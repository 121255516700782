import styled from 'styled-components'
import {down} from 'styled-breakpoints'
import {lighten} from 'polished'

const FooterWrapper = styled.footer`
    overflow: hidden;
    background: ${p => p.theme.colors.footer};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -1px;

    .subfooter{
        background:${p => lighten(0.02,p.theme.colors.footer)};
        width: 100%;
        margin-top: 4px;
        font-size: ${p => p.theme.fontSizes.xs};
        text-align: right;
        display:flex;
        justify-content: space-between;
        color: white;
        ${down('md')}{
            text-align: center;
        }
        #credit{
            &, a{
                color: #Fff;
            }
        }
    }
   
`;
const FooterColumn = styled.div`
    width: 33%;
    ${down('sm')}{
        width: 100%;
    }
    ul{
        padding-left: 0;
    }
`
const FooterListItem = styled.li`
    text-align: center;
    list-style: none;
    a {
        font-size: ${p => p.theme.fontSizes.lg};
        ${down('sm')}{
            font-size: ${p => p.theme.fontSizes["2xl"]}
        }
        position: relative;
        color: ${p => p.theme.colors.white};
        text-decoration: none;
        &:hover::before{
            width: 100%;
            visibility: visible;
        }
        &::before{
            content: '';
            height: 2px;
            background: ${p => p.theme.colors.white};
            width: 0;
            position: absolute;
            visibility: hidden;
            transition: all .3s ease-in-out;
            bottom: 0;
            left: 0
        }
    }
    &#village a{
        font-weight: bold;
    }
`
export {FooterColumn, FooterListItem, FooterWrapper}