import React from 'react'
import {FiFacebook, FiInstagram} from 'react-icons/fi'
import {Link, useStaticQuery, graphql } from "gatsby"
import {FooterWrapper, FooterColumn, FooterListItem} from './footerWrapper'


const Footer = () => {

    const {cabane, bois, deco, sculpture} = useStaticQuery(graphql`
        query GetFooterSlugs{
            bois: strapiCreations(type: {eq: "Boisflotte"}) {
                slug
                type
            }
            cabane: strapiCreations(type: {eq: "Cabane"}) {
                slug
                type
            }
            deco: strapiCreations(type: {eq: "Decoration"}) {
                slug
                type
            }
            sculpture: strapiCreations(type: {eq: "Sculpture"}) {
                slug
                type
            }
        }

    `)

return(
    <FooterWrapper>
        <FooterColumn>
            <ul>     
                <FooterListItem>
                    <Link to={`/${cabane.type}/${cabane.slug}`}>Cabane</Link>
                </FooterListItem>
                <FooterListItem>
                    <Link to={`/${sculpture.type}/${sculpture.slug}`}>Sculpture</Link>
                </FooterListItem>
                <FooterListItem>
                    <Link to={`/${deco.type}/${deco.slug}`}>Décoration</Link>
                </FooterListItem>
                <FooterListItem>
                    <Link to={`/${bois.type}/${bois.slug}`}>Bois flotté</Link>
                </FooterListItem>
            </ul>
        </FooterColumn>
        <FooterColumn>
            <ul>
                <FooterListItem id="village">
                    <Link to="/village">Le Village des Branchés</Link>
                </FooterListItem>
                <FooterListItem>
                    <Link to="/a-propos">A propos </Link><Link to="/a-propos#contact">/ Contact</Link>
                </FooterListItem>
            </ul>
        </FooterColumn>
        <FooterColumn>
            <ul>
                <FooterListItem>
                    <Link to={`/ateliers`}>Ateliers</Link>
                </FooterListItem>
                <FooterListItem>
                    <Link to="/partenaires">Partenaires</Link>
                </FooterListItem>
                <FooterListItem>
                    <a target="_blank" className="facebook-link" href="https://www.facebook.com/tribal.cabane"><FiFacebook size={30} /></a>
                    <a target="_blank" className="insta-link" href="https://www.instagram.com/tribal_cabane/"><FiInstagram size={30} /></a>
                </FooterListItem>
            </ul>
        </FooterColumn>
        <div className="subfooter">
            <div>Photos - tous droits réservés Tribal Cabane</div>
            <div id="credit">Développé avec ❤ par <a href="https://chapatte.co/">Chapatte Corentin</a></div>
        </div>
    </FooterWrapper>

    )
}


export default Footer