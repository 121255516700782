import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import NavbarDropdown from './navDropdown'
import {FiFacebook, FiInstagram} from 'react-icons/fi'

import Image from 'gatsby-image'
import {
  NavbarWrapper,
  NavbarHeader,
  Burger,
  BurgerSlice,
  NavItem,
  NavbarMenu
} from './navbarWrapper'

const MobileNavbar = ({isHomescreen}) => {
    
    const [isOpen, toggleMenu] = useState(false);

    const data = useStaticQuery(graphql`
    query FullNavQuery {
      file(name: {eq: "logo-small"}) {
        id
        name
        logo: childImageSharp {
          fixed(width: 124, height: 60){
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }

      site {
        siteMetadata {
          menuLinks {
            name
            link 
          }
        }
      }
     
      allStrapiCreations {
        nodes {
          titre
          slug
        }
      }
    }
  `)
 
  function generateMenuItems (menuQuery) {
    return menuQuery.site.siteMetadata.menuLinks.map(item => {
      switch(item.link){
        
        default:
          return <NavItem key={item.name}><Link to={item.link}>{item.name}</Link></NavItem> ;
      }
    })
  }

  return (
    <NavbarWrapper>
        <NavbarHeader isHomescreen={isHomescreen}>
              <a target="_blank" className="facebook-link" href="https://www.facebook.com/tribal.cabane"><FiFacebook size={30} /></a>
              <a target="_blank" className="insta-link" href="https://www.instagram.com/tribal_cabane/"><FiInstagram size={30} /></a>
            <Link className="navbar-logo" to="/">
              {!isHomescreen && <Image fixed={data.file.logo.fixed} /> }
            </Link>
            <Burger onClick={() => toggleMenu(!isOpen)}>
              <BurgerSlice />
              <BurgerSlice />
              <BurgerSlice />
            </Burger>
        </NavbarHeader>
        <NavbarMenu className={(isOpen ? "is-active" : "")}>
          {generateMenuItems(data)}          
        </NavbarMenu>
    </NavbarWrapper>  
  )
}
MobileNavbar.defaultProps = {
  isHomescreen: false
}

export default MobileNavbar
